import { useEffect, useState } from 'react'
import { getServiceStatus, ServiceStatus } from '../helpers/api'
import { getErrorMessage } from '../helpers/error'
import { useRollbar } from '@rollbar/react'

export const useServiceStatus = () => {
  const [serviceStatus, setServiceStatus] = useState<ServiceStatus | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const rollbar = useRollbar()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await getServiceStatus()
        setServiceStatus(response.data.status)
      } catch (e) {
        setError(true)
        rollbar.error('Unable to get service status', getErrorMessage(e))
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [rollbar])

  return { serviceStatus, isLoading, error }
}
