import React, { createContext, useContext } from 'react'

import { useServiceStatus } from '../hooks/useServiceStatus'

export interface SystemStatusResponse {
  serviceStatus: string | null
  isLoading: boolean
  error: boolean
}

const SystemStatusContext = createContext<{ systemStatus?: SystemStatusResponse }>({})
export const useSystemStatus = () => useContext(SystemStatusContext)

export function SystemStatusProvider({ children }: { children: React.ReactNode }) {
  const { serviceStatus, isLoading, error } = useServiceStatus()

  return (
    <SystemStatusContext.Provider value={{ systemStatus: { serviceStatus, isLoading, error } }}>
      {children}
    </SystemStatusContext.Provider>
  )
}

export default SystemStatusContext
