import * as FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

export const getConfig: FingerprintJS.GetOptions<true> = {
  extendedResult: true,
  timeout: 20_000,
  tag:
    typeof document !== 'undefined' && document.referrer !== ''
      ? { referrerLink: document.referrer }
      : { referrerLink: null },
}

export const getConfigExtraTag = (extraTags = {}) => {
  const baseConfig: FingerprintJS.GetOptions<true> = {
    extendedResult: true,
    timeout: 20_000,
    tag: {
      referrerLink: typeof document !== 'undefined' && document.referrer !== '' ? document.referrer : null,
      ...extraTags,
    },
  }

  return baseConfig
}
