import React, { createContext, useContext } from 'react'
import { useSmartSignals } from '../hooks/useSmartSignals'
import { SuccessResponse } from '../types/smartSignalsResponse'

const BotdContext = createContext<{
  visitorData?: SuccessResponse
  requestId?: string
  isLoading?: boolean
  hasError?: boolean
  error?: string
  refresh: () => void
}>({
  refresh: () => {
    // noop
  },
})
export const useSmartSignalsContext = () => useContext(BotdContext)

export function BotdProvider({ children }: { children: React.ReactNode }) {
  return <BotdContext.Provider value={useSmartSignals()}>{children}</BotdContext.Provider>
}

export default BotdContext
