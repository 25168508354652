import React, { createContext, useContext, useEffect } from 'react'
import useLocalStorage from '../hooks/useLocalStorage'
import { LocalExperimentFlags, ExperimentValue } from './amplitude'

interface ExperimentContextValue {
  experimentVariants: Record<string, string>
}

const ExperimentContext = createContext<ExperimentContextValue>({ experimentVariants: {} })

const initializeLocalExperiments = () => {
  const experiments = Object.values(LocalExperimentFlags).reduce((acc, flag) => {
    acc[flag] = Math.random() < 0.5 ? ExperimentValue.Control : ExperimentValue.Treatment
    return acc
  }, {})
  return experiments
}

export function ExperimentProvider({ children }: { children: React.ReactNode }) {
  const [experimentVariants, setExperimentVariants] = useLocalStorage<Record<string, string>>('ABLocalFlags', {})

  useEffect(() => {
    if (Object.keys(experimentVariants).length === 0 && Object.keys(LocalExperimentFlags).length > 0) {
      const experiments = initializeLocalExperiments()
      setExperimentVariants(experiments)
    }
  }, [experimentVariants, setExperimentVariants])

  return <ExperimentContext.Provider value={{ experimentVariants }}>{children}</ExperimentContext.Provider>
}

export const useExperiments = () => useContext(ExperimentContext)
