/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 183
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/fingerprint/Fingerprint/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/fingerprint/Fingerprint/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser'

export type Environment = 'production' | 'development'

export const ApiKey: Record<Environment, string> = {
  production: '',
  development: '',
}

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '183',
    branch: 'main',
    source: 'web',
    versionId: '84564406-570e-4f4c-bb9a-cfd9a32270fa',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
}

export interface LoadOptionsBase {
  disabled?: boolean
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment
  client?: { configuration?: BrowserOptions }
}
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions } }
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient } }

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance

export interface IdentifyProperties {
  '[Experiment] a-a-test'?: string
  /**
   * Fingerprint's bot detection result.
   */
  botDetected?: string
  currentVisitorId?: string
  customer?: any
  customer_id?: any
  'fingerprint-device-id'?: any
  initial_referrer?: string
  initial_referring_domain?: string
  initial_utm_campaign?: string
  initial_utm_content?: string
  initial_utm_id?: string
  initial_utm_medium?: string
  initial_utm_source?: string
  initial_utm_term?: string
  /**
   * Property assigned during account setup as a result of the Forked Path step completed.
   */
  isDeveloper?: boolean
  /**
   * * True, if a user's email contains @fingerprint.com or @fingerprintjs.com
   *
   * * False, if otherwise.
   */
  isInternal?: boolean
  'q3-2023-'?: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  screenHeight?: number
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  screenWidth?: number
  subscription_id?: any
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  viewportHeight?: number
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  viewportWidth?: number
  visitorId?: string
}

export interface GroupProperties {
  '[Amplitude] Group ID'?: any
  '[Amplitude] Group name'?: any
  /**
   * The name of the billing plan the subscription is associated with.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Fingerprint Pro, Fingerprint Pro Plus, Enterprise |
   */
  billingPlanName?: 'Fingerprint Pro' | 'Fingerprint Pro Plus' | 'Enterprise'
  /**
   * A billing type, also referred to as a agreement type, defines the business relationship between customer and Fingerprint.
   *
   * `subscription.type` in MGMT-API is an application level property. Therefore, the values on the customer level must be derived based on data for all the applications under a given customer account. Values:
   *
   * * Subscription - all applications are on subscription.type = paid;
   *
   * * Contract - all applications are on subscription.type = prepaid;
   *
   * * Mix - applications are both, at least one application on subscription.type = paid and at least one application on subscription.type = prepaid.
   *
   * * None - none of the application is tight with any billing plan.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Subscription, Contract, Mix, None |
   */
  billingType?: 'Subscription' | 'Contract' | 'Mix' | 'None'
  /**
   * Unique identifier of the subscription.
   */
  subscriptionID?: string
}

export interface GetDemoContactedProperties {
  /**
   * Property used for experimentation.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Control, Treatment |
   */
  variant?: 'Control' | 'Treatment'
  /**
   * Name of the variant that provides detailed information behind the experience. In case of doubt of what values to set in the context of a specific experiment, please consult the Product Analytics team.
   */
  variantName?: string
}

export interface SalesContactedProperties {
  /**
   * Property used for experimentation.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Control, Treatment |
   */
  variant?: 'Control' | 'Treatment'
  /**
   * Name of the variant that provides detailed information behind the experience. In case of doubt of what values to set in the context of a specific experiment, please consult the Product Analytics team.
   */
  variantName?: string
}

export interface SignupOptionsShownProperties {
  /**
   * Name of the domain
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | fingerprint.com, dashboard.fingerprint.com |
   */
  domainName: 'fingerprint.com' | 'dashboard.fingerprint.com'
}

export interface SignupStartedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | password, google sso, github sso, microsoft sso |
   */
  signupType: 'password' | 'google sso' | 'github sso' | 'microsoft sso'
}

export interface WebCtaClickedProperties {
  /**
   * Bot Detection Smart Signal
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  botDetected: string
  /**
   * Name on a button on a marketing website.
   */
  ctaName: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'Web Page Path': string
}

export interface WebFormSubmittedProperties {
  /**
   * Bot Detection Smart Signal
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  botDetected: string
  /**
   * Name on a form / context for subimitting information
   */
  formName: string
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  'Web Page Path': string
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties
  }
}

export class Group implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.GROUP_IDENTIFY

  constructor(public event_properties?: GroupProperties) {
    this.event_properties = event_properties
  }
}

export class GetDemoContacted implements BaseEvent {
  event_type = 'Get Demo Contacted'

  constructor(public event_properties?: GetDemoContactedProperties) {
    this.event_properties = event_properties
  }
}

export class RoiCalculatorFormSubmitted implements BaseEvent {
  event_type = 'ROI Calculator Form Submitted'
}

export class RoiCalculatorUsed implements BaseEvent {
  event_type = 'ROI Calculator Used'
}

export class SalesContacted implements BaseEvent {
  event_type = 'Sales Contacted'

  constructor(public event_properties?: SalesContactedProperties) {
    this.event_properties = event_properties
  }
}

export class SignupOptionsShown implements BaseEvent {
  event_type = 'Signup Options Shown'

  constructor(public event_properties: SignupOptionsShownProperties) {
    this.event_properties = event_properties
  }
}

export class SignupStarted implements BaseEvent {
  event_type = 'Signup Started'

  constructor(public event_properties: SignupStartedProperties) {
    this.event_properties = event_properties
  }
}

export class WebCtaClicked implements BaseEvent {
  event_type = 'Web CTA Clicked'

  constructor(public event_properties: WebCtaClickedProperties) {
    this.event_properties = event_properties
  }
}

export class WebFormSubmitted implements BaseEvent {
  event_type = 'Web Form Submitted'

  constructor(public event_properties: WebFormSubmittedProperties) {
    this.event_properties = event_properties
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> }

const getVoidPromiseResult = () => ({ promise: Promise.resolve() })

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

  /**
   * Set Group for the current user
   *
   * @param groupType The group type.
   * @param groupName The group name.
   * @param options Optional event options.
   */
  setGroup(groupType: string, groupName: string | string[], options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.setGroup(groupType, groupName, options);
  }

  /**
   * Identify a group and set group properties.
   *
   * @param groupType The group type.
   * @param groupName The group name.
   * @param properties The group properties.
   * @param options Optional event options.
   */
  groupIdentify(
    groupType: string,
    groupName: string | string[],
    properties?: GroupProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.groupIdentify(groupType, groupName, amplitudeIdentify, options);
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Get Demo Contacted
   *
   * [View in Tracking Plan](https://data.amplitude.com/fingerprint/Fingerprint/events/main/latest/Get%20Demo%20Contacted)
   *
   * **Description**: This event tracks the demo leads submitted via form available on a dedicated get demo page.
   *
   * **Triggering Conditions:** Click on submit button onSuccess for the <https://fingerprint.com/demo/#get-demo> page.
   *
   * Owner: Alexander Goodwin
   *
   * @param properties The event's properties (e.g. variant)
   * @param options Amplitude event options.
   */
  getDemoContacted(
    properties?: GetDemoContactedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GetDemoContacted(properties), options);
  }

  /**
   * ROI Calculator Form Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/fingerprint/Fingerprint/events/main/latest/ROI%20Calculator%20Form%20Submitted)
   *
   * **Description**: Event indicating that a user has submitted a form ebedded as part of the ROI Calculator experience.
   *
   * **Triggering Condition:** button click, form submit on success
   *
   * Owner: Natanael Galfrascoli
   *
   * @param options Amplitude event options.
   */
  roiCalculatorFormSubmitted(
    options?: EventOptions,
  ) {
    return this.track(new RoiCalculatorFormSubmitted(), options);
  }

  /**
   * ROI Calculator Used
   *
   * [View in Tracking Plan](https://data.amplitude.com/fingerprint/Fingerprint/events/main/latest/ROI%20Calculator%20Used)
   *
   * **Description:** Triggered whenever a user uses the ROI calculator
   *
   * **Triggering Condition**: button click
   *
   * Owner: Natanael Galfrascoli
   *
   * @param options Amplitude event options.
   */
  roiCalculatorUsed(
    options?: EventOptions,
  ) {
    return this.track(new RoiCalculatorUsed(), options);
  }

  /**
   * Sales Contacted
   *
   * [View in Tracking Plan](https://data.amplitude.com/fingerprint/Fingerprint/events/main/latest/Sales%20Contacted)
   *
   * **Description**: This event tracks the sales leads submitted via form available on a dedicated pricing page.
   *
   * **Triggering Condition:** Click on submit button onSuccess for the <https://fingerprint.com/contact-sales/> page.
   *
   * Owner: Alexander Goodwin
   *
   * @param properties The event's properties (e.g. variant)
   * @param options Amplitude event options.
   */
  salesContacted(
    properties?: SalesContactedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SalesContacted(properties), options);
  }

  /**
   * Signup Options Shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/fingerprint/Fingerprint/events/main/latest/Signup%20Options%20Shown)
   *
   * **Description**: Event to track when different signup options are displayed to users on either marketing websites (fingerprint.com) or dashboard (dashboard.fingerprint.com).
   *
   * **Triggering Conditions**: Page Load
   *
   * @param properties The event's properties (e.g. domainName)
   * @param options Amplitude event options.
   */
  signupOptionsShown(
    properties: SignupOptionsShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignupOptionsShown(properties), options);
  }

  /**
   * Signup Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/fingerprint/Fingerprint/events/main/latest/Signup%20Started)
   *
   * **Description**: This event tracks when a user begins the signup process on the platform.
   *
   * **Trigerring Condition**: Front-end event, button click, on CTAs like "Start Free with XYZ", or "Continue with XYZ"
   *
   * @param properties The event's properties (e.g. signupType)
   * @param options Amplitude event options.
   */
  signupStarted(
    properties: SignupStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignupStarted(properties), options);
  }

  /**
   * Web CTA Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/fingerprint/Fingerprint/events/main/latest/Web%20CTA%20Clicked)
   *
   * Event indicating that a user clicked on the "Contact Sales" button
   *
   * Owner: Javier Ocañas
   *
   * @param properties The event's properties (e.g. botDetected)
   * @param options Amplitude event options.
   */
  webCtaClicked(
    properties: WebCtaClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebCtaClicked(properties), options);
  }

  /**
   * Web Form Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/fingerprint/Fingerprint/events/main/latest/Web%20Form%20Submitted)
   *
   * Event indicating that a user has submitted a web form on the fingerprint.com pages
   *
   * Owner: Javier Ocañas
   *
   * @param properties The event's properties (e.g. botDetected)
   * @param options Amplitude event options.
   */
  webFormSubmitted(
    properties: WebFormSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebFormSubmitted(properties), options);
  }
}

export const ampli = new Ampli()

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions

export type BrowserClient = amplitude.Types.BrowserClient
export type BaseEvent = amplitude.Types.BaseEvent
export type IdentifyEvent = amplitude.Types.IdentifyEvent
export type GroupEvent = amplitude.Types.GroupIdentifyEvent
export type Event = amplitude.Types.Event
export type EventOptions = amplitude.Types.EventOptions
export type Result = amplitude.Types.Result
