import React, { createContext, useContext, useState, useEffect } from 'react'
import useLocalStorage from './useLocalStorage'
import { useUserLocation } from './useUserLocation'

export enum CookieChoice {
  NotDefined = 'not-defined',
  Required = 'required',
  Advertising = 'advertising',
  Analytics = 'analytics',
}

interface ContextType {
  cookieChoice: CookieChoice[]
  setCookieChoice: (choice: CookieChoice[]) => void
  showBanner: boolean
}

const CookieChoiceContext = createContext<ContextType>({
  cookieChoice: [CookieChoice.NotDefined],
  setCookieChoice: () => {},
  showBanner: false,
})

export function CookieChoiceProvider({ children }: { children: React.ReactNode }) {
  const { isEuUser, userRegion } = useUserLocation()
  const [localCookieChoice, setLocalCookieChoice] = useLocalStorage<CookieChoice[]>('cookieChoice', [
    CookieChoice.NotDefined,
  ])
  const [cookieChoice, setCookieChoice] = useState(localCookieChoice)
  const [showBanner, setShowBanner] = useState(false)

  // Check if the user's region is defined and if they are not in the EU
  // Set all cookies (except NotDefined) for non-EU users
  useEffect(() => {
    if (userRegion !== undefined && !isEuUser) {
      setCookieChoice([CookieChoice.Required, CookieChoice.Advertising, CookieChoice.Analytics])
    }
  }, [userRegion, isEuUser])

  // Show banner if the user is in the EU and choice includes NotDefined
  useEffect(() => {
    setShowBanner(cookieChoice.includes(CookieChoice.NotDefined) && isEuUser)
  }, [cookieChoice, isEuUser])

  // Set local storage only when internal cookieChoice changes
  useEffect(() => {
    setLocalCookieChoice(cookieChoice)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieChoice])

  return (
    <CookieChoiceContext.Provider value={{ cookieChoice, setCookieChoice, showBanner }}>
      {children}
    </CookieChoiceContext.Provider>
  )
}

export const useCookieChoice = () => useContext(CookieChoiceContext)
