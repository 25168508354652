export const FPJS_PUBLIC_TOKEN = process.env.GATSBY_FPJS_PUBLIC_TOKEN

export const FPJS_SCRIPT_URL_PATTERN = process.env.GATSBY_FPJS_SCRIPT_URL_PATTERN
export const GTM_TOKEN = process.env.GATSBY_GTM_TOKEN ?? 'test_gtm_token'

export const FPJS_SECRET_TOKEN = process.env.GATSBY_FPJS_SECRET_TOKEN ?? 'test_fpjs_secret_token'

export const FPJS_VISITORS_ENDPOINT =
  process.env.GATSBY_FPJS_VISITORS_ENDPOINT ?? 'https://h1.fingerprintjs.com/pamplemousse/'

export const FPJS_MGMT_API_HOST = process.env.GATSBY_FPJS_MGMT_API_HOST ?? ''

export const BOTD_PUBLIC_TOKEN = process.env.GATSBY_BOTD_PUBLIC_TOKEN ?? ''

export const BOTD_SECRET_TOKEN = process.env.GATSBY_BOTD_SECRET_TOKEN ?? ''

export const BOTD_VERIFY_ENDPOINT = process.env.GATSBY_BOTD_VERIFY_ENDPOINT ?? 'https://botd.fpapi.io/api/v1/verify'

export const BOTD_VERIFY_AGENT_ENDPOINT = process.env.GATSBY_BOTD_VERIFY_AGENT_ENDPOINT ?? 'https://api.fpjs.io'

export const FPJS_TLS_ENDPOINT = process.env.GATSBY_FPJS_TLS_ENDPOINT ?? 'https://demo.fpxau.net/'

export const FPJS_INGRESS_ENDPOINT = process.env.GATSBY_FPJS_ENDPOINT ?? 'https://fpa.fingerprint.com'

export const FPJS_USE_TLS = process.env.GATSBY_FPJS_USE_TLS === 'true' || false

export const AMPLITUDE_API_KEY = process.env.GATSBY_AMPLITUDE_API_KEY ?? ''

export const AMPLITUDE_API_ENDPOINT = process.env.GATSBY_AMPLITUDE_API_ENDPOINT ?? ''

export const AMPLITUDE_EXPERIMENT_DEPLOY_API_KEY = process.env.GATSBY_AMPLITUDE_EXPERIMENT_DEPLOY_API_KEY ?? ''

export const GREENHOUSE_COMPANY_ID = process.env.GATSBY_GREENHOUSE_COMPANY_ID ?? 'fingerprinttest'

export const RECAPTCHA_BOTD_PUBLIC_KEY = process.env.GATSBY_RECAPTCHA_BOTD_PUBLIC_KEY ?? ''

export const APP_STORE_DEMO_URL = process.env.GATSBY_APP_STORE_DEMO_URL
export const GOOGLE_PLAY_DEMO_URL = process.env.GATSBY_GOOGLE_PLAY_DEMO_URL

export const BOTD_FUZZER_ENDPOINT = process.env.GATSBY_BOTD_FUZZER_ENDPOINT ?? ''

export const FPJS_REGION = process.env.GATSBY_FPJS_REGION
export const FPJS_DASHBOARD_ENDPOINT = process.env.GATSBY_FPJS_DASHBOARD_ENDPOINT
export const GITHUB_API_TOKEN = process.env.GATSBY_GITHUB_API_TOKEN
export const MAPBOX_ACCESS_TOKEN = process.env.GATSBY_MAPBOX_ACCESS_TOKEN
export const ROLLBAR_ACCESS_TOKEN = process.env.GATSBY_ROLLBAR_ACCESS_TOKEN ?? ''
export const BRANCH = process.env.BRANCH

export const HOST =
  process.env.GATSBY_VERCEL_ENV === 'preview' && typeof window !== 'undefined'
    ? `https://${window.location.hostname}`
    : 'https://fingerprint.com'

export const GIT_SHA = process.env.GATSBY_VERCEL_GIT_COMMIT_SHA

export const IS_PRODUCTION = process.env.GATSBY_VERCEL_ENV === 'production'

export const IS_VERCEL = process.env.GATSBY_IS_VERCEL === 'true'

export const RANDOM_BOTD = process.env.GATSBY_RANDOM_BOTD ?? '6'

export const RANDOM_AMPLI = process.env.GATSBY_RANDOM_AMPLI ?? '6'

export const ALGOLIA_APP_ID = process.env.GATSBY_ALGOLIA_APP_ID ?? 'O4G7VRFVS2'
export const ALGOLIA_SEARCH_KEY = process.env.GATSBY_ALGOLIA_SEARCH_KEY ?? '09ece33ac764adb69b84dafd4158047d'
export const ALGOLIA_INDEX_NAME = process.env.GATSBY_ALGOLIA_INDEX_NAME ?? 'web'

export const CONTACT_SALES_THANK_YOU =
  process.env.GATSBY_CONTACT_SALES_THANK_YOU ?? 'https://try.fingerprint.com/thank-you-contact-sales'
