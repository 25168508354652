import {
  FPJS_MGMT_API_HOST,
  GREENHOUSE_COMPANY_ID,
  IS_VERCEL,
  FPJS_VISITORS_ENDPOINT,
  FPJS_SECRET_TOKEN,
  AMPLITUDE_API_KEY,
} from '../constants/env'
import { BASE_URL } from '../constants/content'
import axios from 'axios'
import { generateRandomString } from './common'

export async function loadFpjsHistory(visitorId: string) {
  const randomPath = generateRandomString(4)
  let response
  if (IS_VERCEL) {
    response = await axios.get(`${BASE_URL}/${randomPath}/`, {
      headers: {
        'x-vercel-function': 'visits',
        'x-visitor-id': visitorId,
      },
    })
  } else {
    response = await axios.get(`${FPJS_VISITORS_ENDPOINT}${visitorId}?token=${FPJS_SECRET_TOKEN}&limit=21`)
  }

  return response.data
}

export enum IpRegion {
  APAC = 'APAC',
  EMEA = 'EMEA',
  AMERICAS = 'Americas',
}

interface TrackParams {
  visitorId?: string
  ipRegion?: 'APAC' | 'EMEA' | 'Americas'
}
interface CreateNewLeadParams extends TrackParams {
  email: string
  formName?: string
  url?: string
  jobTitle?: string
  description?: string
  apiCallsQuestion?: string
  landingPage?: string
  previousPage?: string
  utmParams?: Record<string, string>
}

export async function createNewLead({
  formName,
  email,
  url,
  jobTitle,
  description,
  apiCallsQuestion,
  landingPage,
  previousPage,
  utmParams,
  visitorId,
  ipRegion,
}: CreateNewLeadParams) {
  return await fetch(`${BASE_URL}/api/hubspot/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name: formName,
      email,
      url,
      description,
      apiCallsQuestion,
      jobTitle,
      utm_info: utmParams,
      landingPage,
      previousPage,
      visitorId,
      ipRegion,
    }),
  })
}

interface SubscribeToNewsletterParams extends TrackParams {
  email: string
  utmParams: Record<string, string>
}
export async function subscribeToNewsletter({ email, utmParams, visitorId, ipRegion }: SubscribeToNewsletterParams) {
  return await fetch(`${BASE_URL}/api/hubspot-newsletter/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      utm_info: utmParams,
      visitorId,
      ipRegion,
    }),
  })
}

export async function fetchFromGreenhouse(jobId?: string) {
  let response

  if (IS_VERCEL) {
    if (jobId) {
      response = await axios.get(`${BASE_URL}/greenhouse/`, {
        params: {
          action: 'jobInfo',
          jobId,
        },
      })
    } else {
      response = await axios.get(`${BASE_URL}/greenhouse/`, {
        params: {
          action: 'listings',
        },
      })
    }
  } else {
    if (jobId) {
      response = await axios.get(`https://api.greenhouse.io/v1/boards/${GREENHOUSE_COMPANY_ID}/jobs/${jobId}`)
    } else {
      response = await axios.get(`https://api.greenhouse.io/v1/boards/${GREENHOUSE_COMPANY_ID}/jobs?content=true`)
    }
  }

  return response.data
}
interface ContactSupportParams extends TrackParams {
  email: string
  description: string
  utmParams: Record<string, string>
}

export async function contactSupport({ email, description, utmParams, visitorId, ipRegion }: ContactSupportParams) {
  return fetch(`${BASE_URL}/api/hubspot-support/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      description,
      utm_info: utmParams,
      visitorId,
      ipRegion,
    }),
  })
}

interface ContactPressParams extends TrackParams {
  email: string
  message: string
  utmParams: Record<string, string>
}

export async function contactPress({ email, message, utmParams, visitorId, ipRegion }: ContactPressParams) {
  return fetch(`${BASE_URL}/api/hubspot-press/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      message,
      utm_info: utmParams,
      visitorId,
      ipRegion,
    }),
  })
}

interface ContactPartnersParams {
  email: string
  formName: string
  company: string
  message: string
}

export async function contactPartners({ email, formName, company, message }: ContactPartnersParams) {
  return fetch(`${BASE_URL}/api/hubspot-partners/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      name: formName,
      company,
      message,
    }),
  })
}

interface SendExposureEventParams {
  device_id: string
  event_properties: {
    flag_key: string
    variant: string
  }
}

export async function sendExposureEvent({ device_id, event_properties }: SendExposureEventParams) {
  let response

  if (IS_VERCEL) {
    const vercelPayload = {
      device_id: device_id,
      flag_key: event_properties.flag_key,
      variant: event_properties.variant,
    }

    response = await axios.post(`${BASE_URL}/amp-exp/`, vercelPayload)
  } else {
    const amplitudePayload = {
      api_key: AMPLITUDE_API_KEY,
      events: [
        {
          event_type: '$exposure',
          user_id: '',
          device_id: device_id,
          event_properties: {
            flag_key: event_properties.flag_key,
            variant: event_properties.variant,
          },
        },
      ],
    }

    response = await axios.post(`https://api2.amplitude.com/2/httpapi`, amplitudePayload)
  }

  return response.data
}

export type OAuthProvider = 'GoogleOAuth' | 'GitHubOAuth' | 'MicrosoftOAuth'
export type OAuthLinksResponse = {
  ok: boolean
  data: { [key in OAuthProvider]: string }
}

export async function getOauthLinks(): Promise<OAuthLinksResponse | null> {
  const response = await axios.get(`${FPJS_MGMT_API_HOST}/sso/oauth`)
  return response.data
}

interface CreateNewLeadRoiCalculatorParams extends TrackParams {
  email: string
  utmParams?: Record<string, string>
  roiApproximateFraudLoss: number
  roiAverageTransactionValue: number
  roiChargebackRate: number
  roiEstimatedCostOfFingerprint: number
  roiEstimatedSavingWithFingerprint: number
  roiExpectedTransactionsPerMonth: number
}

export function createNewLeadRoiCalculator({
  email,
  utmParams,
  visitorId,
  ipRegion,
  roiApproximateFraudLoss,
  roiAverageTransactionValue,
  roiChargebackRate,
  roiEstimatedCostOfFingerprint,
  roiEstimatedSavingWithFingerprint,
  roiExpectedTransactionsPerMonth,
}: CreateNewLeadRoiCalculatorParams) {
  return fetch(`${BASE_URL}/api/roi/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      utm_info: utmParams,
      visitorId,
      ipRegion,
      roi_approximate_fraud_loss: roiApproximateFraudLoss,
      roi_average_transaction_value: roiAverageTransactionValue,
      roi_chargeback_rate: roiChargebackRate,
      roi_estimated_cost_of_fingerprint: roiEstimatedCostOfFingerprint,
      roi_estimated_saving_with_fingerprint: roiEstimatedSavingWithFingerprint,
      roi_expected_transactions_per_month: roiExpectedTransactionsPerMonth,
    }),
  })
}

export type ServiceStatus = 'operational' | 'downtime' | 'degraded'
export type ServiceStatusResponse = {
  ok: boolean
  data: {
    status: ServiceStatus
    updatedAt: string
    requestedAt: string
  }
}

export async function getServiceStatus(): Promise<ServiceStatusResponse> {
  const response = await axios.get(`${FPJS_MGMT_API_HOST}/service-status`)
  return response.data
}

interface CreateNewLeadRiskAssessmentParams extends TrackParams {
  email: string
  utmParams?: Record<string, string>
  description: string
  primaryRiskConcern: string
  estimatedWebsiteTraffic: string
}

export function createNewLeadRiskAssessment({
  email,
  utmParams,
  visitorId,
  ipRegion,
  description,
  primaryRiskConcern,
  estimatedWebsiteTraffic,
}: CreateNewLeadRiskAssessmentParams) {
  return fetch(`${BASE_URL}/risk/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      utm_info: utmParams,
      visitorId,
      ipRegion,
      message: description,
      primary_risk_concern: primaryRiskConcern,
      estimated_website_traffic: estimatedWebsiteTraffic,
    }),
  })
}

interface CreateNewLeadWebinarFormParams extends TrackParams {
  email: string
  firstName: string
  lastName: string
  jobTitle: string
  company: string
  hubspotFormId: string
  utmParams?: Record<string, string>
}

export function createNewLeadWebinarForm({
  email,
  firstName,
  lastName,
  jobTitle,
  company,
  visitorId,
  ipRegion,
  utmParams,
  hubspotFormId,
}: CreateNewLeadWebinarFormParams) {
  return fetch(`${BASE_URL}/webinar/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      utm_info: utmParams,
      visitorId,
      ipRegion,
      jobTitle,
      company,
      firstName,
      lastName,
      hubspotFormId,
    }),
  })
}
